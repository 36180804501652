import ApplicationController from './application_controller'
import { useTransition, useClickOutside } from 'stimulus-use';


export default class extends ApplicationController {
  static targets = ['drawer']

  connect() {
    useTransition(this, {
      element: this.drawerTarget,
      enterActive: 'transition ease-out duration-800',
      enterFrom: 'transform translate-y-full',
      enterTo: 'transform translate-y-0',
      leaveActive: 'transition ease-in duration-800',
      leaveFrom: 'transform translate-y-0',
      leaveTo: 'transform translate-y-full',
      hiddenClass: 'd-none',
      // set this, because the item *starts* in an open state
      transitioned: false,
    });
    useClickOutside(this, { element: this.drawerTarget });
  }

  useClickOutsidek(e) {
    this.leave();
  }

  close(e) {
    e.preventDefault();
    this.leave();
  }

  open(e) {
    e.preventDefault();
    this.enter();
  }

  toggle(e) {
    e.preventDefault();
    this.toggleTransition();
  }

}