import ApplicationController from './application_controller'
import SignaturePad from 'signature_pad'

export default class extends ApplicationController {
  static targets = ["canvas", "input"]
  connect() {
    super.connect()
    this.signaturePad = new SignaturePad(this.canvasTarget)
    this.signaturePad.addEventListener("endStroke", this.endStroke)
    this.resizeCanvas()
    if (this.inputTarget.value) {
      this.signaturePad.fromDataURL(this.inputTarget.value)
    }
  }

  openModal(e) {
    console.log("openModal")
    console.log(e)
    this.createEvent("modal_open")
  }

  closeModal() {
    this.createEvent("modal_close")
  }

  disconnect() {
    this.signaturePad.off()
  }

  clear() {
    this.signaturePad.clear()
    this.inputTarget.value = ""
  }

  endStroke = (_) => {
    // let data = this.signaturePad.toDataURL()
    // this.inputTarget.value = this.signaturePad.toDataURL("image/svg+xml")
    this.inputTarget.value = this.signaturePad.toDataURL()
  }

  resizeCanvas() {
    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    // var ratio = Math.max(window.devicePixelRatio || 1, 1)
    // this.canvasTarget.width = this.canvasTarget.offsetWidth * ratio
    // this.canvasTarget.height = this.canvasTarget.offsetHeight * ratio
    // this.canvasTarget.getContext("2d").scale(ratio, ratio)
  }

  createEvent(event_name) {
    const event = new CustomEvent(event_name)
    window.dispatchEvent(event)
  }
}