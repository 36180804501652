import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    "finalTargetList",
    "filterInput",
    "targetList",
    "sourceList",
    "inactiveContainer",
    "editingContainer",
  ]

  connect() {
    this.isEditing = false
    this.sourceListTargets.forEach((item) => {
      item.addEventListener("click", () => {
        this.moveItem(item)
      })
    })

    this.targetListTargets.forEach((item) => {
      item.addEventListener("click", () => {
        this.removeItem(item)
      })
    })

    this.filterInputTarget.addEventListener("input", () => {
      this.filterList()
    })
  }

  showEdit() {
    this.createEvent('modal_open')
  }

  hideEdit() {
    this.createEvent('modal_close')
    this.clearAll()

  }

  clearAll() {
    this.targetListTargets.forEach((item) => {
      this.removeItem(item)
    })
    this.finalTargetListTargets.forEach((item) => {
      item.querySelectorAll("li").forEach((li) => {
        if (li.dataset.persisted) {
        } else {
          li.remove()
        }
      })
    })
    this.filterList()
  }


  moveItem(item) {
    this.targetListTarget.appendChild(
      this.selectedListItem(item, true, true)
    )
    this.finalTargetListTarget.appendChild(
      this.selectedListItem(item, false, false)
    )
    item.remove()
    // document.querySelector(".no-activities").classList.add("d-none") // hide

    this.filterList()
  }

  selectedListItem(item, addHiddenInput, addRemoveButton) {
    const activityId = item.dataset.activityId
    const activityName = item.textContent.trim()
    const name = this.element.dataset.name
    const listItem = document.createElement("li")
    listItem.dataset.activityId = activityId
    const container = document.createElement("span")
    container.classList.add("d-flex", "align-items-center")

    const label = document.createElement("label")
    label.textContent = activityName
    container.appendChild(label)

    if (addHiddenInput) {
      const input = document.createElement("input")
      input.type = "hidden"
      input.name = `${name}[${this.targetListTargets.length}][activity_id]`
      input.value = activityId
      container.appendChild(input)
    }

    const context = this
    if (addRemoveButton) {
      const removeButton = document.createElement("span")
      const btn = document.getElementById("XSvg").cloneNode(true)
      removeButton.appendChild(btn)

      removeButton.addEventListener("click", () => {
        context.removeItem(listItem)
      })
      container.appendChild(removeButton)
      // listItem.appendChild(removeButton)
    }
    listItem.appendChild(container)

    return listItem
  }

  removeItem(item) {
    const activityId = item.querySelector("input[type=hidden]").value
    const activityName = item.querySelector("label").textContent.trim()

    const li = document.createElement("li")
    li.dataset.activityId = activityId
    li.textContent = activityName

    li.addEventListener("click", () => {
      this.moveItem(li)
    })
    document.querySelectorAll('[data-activity-id="' + activityId + '"]').forEach(e => e.remove())

    this.sourceListTarget.appendChild(li)

    if (this.targetListTargets.length == 0) {
      // document.querySelector(".no-activities").classList.remove("d-none") // show
    }

    this.filterList()
  }

  filterList() {
    const filterText = this.filterInputTarget.value.trim().toLowerCase()

    this.sourceListTargets.forEach((item) => {
      const activityName = item.textContent.trim().toLowerCase()

      if (activityName.includes(filterText)) {
        item.style.display = "block"
      } else {
        item.style.display = "none"
      }
    })
  }

  get sourceListTargets() {
    return this.sourceListTarget.querySelectorAll("li")
  }

  get targetListTargets() {
    return this.targetListTarget.querySelectorAll("li")
  }

  createEvent(event_name) {
    const event = new CustomEvent(event_name)
    window.dispatchEvent(event)
  }
}