import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "source", "loader"
  ]

  connect() {
  }

  initialize() {
  }

  submit(event) {
    this.loaderTarget.classList.remove('d-none')
    window.onbeforeunload = function () {
      return true;
    };
  }

  complete(event) {
    this.loaderTarget.classList.add('d-none')
    window.onbeforeunload = null;
  }
}
