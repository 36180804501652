import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "source", "filterable", "parent"
  ]

  static values = {
    index: Number
  }

  connect() {
    this.detached = []
  }

  initialize() {
  }

  filter(event) {
    let lowerCaseFilterTerm = this.sourceTarget.value.toLowerCase()

    let allTargets = this.detached.concat(this.filterableTargets)

    allTargets.forEach((el, i) => {
      let filterableKey = el.getAttribute("data-filter-key")

      if (filterableKey.includes(lowerCaseFilterTerm)) {
        this.attach(el)
      } else {
        this.detach(el)
      }
    })
  }

  detach(node) {
    if (!this.detached.includes(node)) {
      this.detached.push(node)
      this.parentTarget.removeChild(node)
    }
  }

  attach(node) {
    if (this.detached.includes(node)) {
      let sibling = this.filterableTargets.find(ft => parseInt(ft.getAttribute('data-index')) > parseInt(node.getAttribute('data-index')))
      if (sibling) {
        this.parentTarget.insertBefore(node, sibling)
      } else {
        this.parentTarget.append(node)
      }
      this.detached = this.detached.filter(target => target !== node)
    }
  }
}
