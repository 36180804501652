import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "messageBody", "identityToggle", "submitButton", "modal", "submitLink"
  ]

  static values = {
    name: String
  }

  connect() {
  }

  initialize() {
  }

  toggleIdentity() {
    let newValue = ""
    if (this.identityToggleTarget.checked) {
      newValue = this.messageBodyTarget.value.replace("DateDocs User", this.nameValue)
    } else {
      newValue = this.messageBodyTarget.value.replace(this.nameValue, "DateDocs User")
    }
    this.messageBodyTarget.value = newValue
  }

  submit() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = true
    }
    else {
      this.submitLinkTarget.style = "opacity: 0.8; pointer-events: none;"
    }
  }

  complete() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = false
    }
    else {
      this.submitLinkTarget.style = "opacity: 1; pointer-events: auto;"
    }
  }
}
